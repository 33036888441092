

html,body {
  height: 100%;
  margin: 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;

  -webkit-touch-callout: none;
  cursor: default;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
	height: 100%;
	
}

.backdrop{
	margin-top: -5%;
	position: relative;
	z-index: 0;
}


.background{
	margin-top: 5%;
	position: fixed;
	height: 100%; 
	width: 100%;
	opacity: .40;
	display: flex;
	
	align-items: flex-start;
	justify-content: flex-start;
	
	background: rgb(220, 220, 220);
	z-index: -3;

}

.background2{
	margin-top: 5%;
	position: fixed;
	height: 18.38%; 
	width: 100%;
	background: white;	

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;	

	color: rgb(105, 105, 105);
	font-size: 600%;
	font-weight: bold;
	z-index: 3;

}



.background-2{
	margin-top: 5%;
	position: fixed;
	height: 100%; 
	width: 100%;
	opacity: .40;
	display: flex;
	
	align-items: flex-start;
	justify-content: flex-start;
	
	background: white;
	z-index: -3;

}

.background2-2{
	margin-top: 5%;
	position: fixed;
	height: 18.38%; 
	width: 100%;
	background: black;	

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;	

	color: white;
	font-size: 600%;
	font-weight: bold;
	z-index: 3;

}

.background-3{
	position: static;
	height: 100%; 
	width: 100%;
	opacity: .40;
	display: flex;
	
	align-items: flex-start;
	justify-content: flex-start;
	
	background: white;
	z-index: -3;

}

.background3-2{
	margin-top: 5%;
	position: static;
	height: 18.38%; 
	width: 100%;
	background: black;	

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;	

	color: white;
	font-size: 600%;
	font-weight: bold;
	z-index: 3;

}

.home{
	position: relative;	
}

.name{
	position: relative;
	margin-top: 3.5%;
	z-index: 3;
	margin-bottom: 0;

}

.fade{
	opacity: .80;
}

.navbar{
	position: absolute;
	margin-left: 75vw;
	margin-top: 12vw;

	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;	


	width: 25%;
	background: transparent;
	z-index: 4;
}

.inNavbar{
	position: absolute;
	width: 11%;
	min-width: 39.59px;
	margin-right: 2%;
}


.homepage{
	height: 100%;
	z-index: -1;
}

.header{
	margin-top: 5%;
	margin-bottom: 0;
	font-size: 200%;
	font-weight: normal;
	color: transparent; 
}

.title{
	margin: 0;
	font-size: 150%;
	color: transparent; 
}

.featured{
	height: 30%;
	width: 100%;
	justify-content: center;
	min-width: 100vw;
}


.about{
	margin: 5%;
	height: 100%;
}

.flex{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.AFlex{
	margin-top: -3%;
	width: 100%;
	height: 30%;
	display: flex;
	align-items: center;
}

.inFlex{
	margin: 12vw;
	align-items: center;
	flex: 40%;
	height: 100%;
}

.grid{
	display: grid;
	grid-template-columns: 100%; 
}

.grid2{
	display: grid;
	grid-template-columns: 50% 50%;
}

.onlight{
	background: white;
	opacity: .80;
	z-index: 1;
	position: relative;
	margin-top: 1%;
	margin-left:1%; 
	margin-right:1%;
	height: 250px;
	width: 11%;

	
	background-position: center center;
	transition: box-shadow 0.5s;
  	will-change: transform;


	border: solid;
	border-width: .1px;
	border-color: transparent;
	display: inline-block; 
	padding: 0;	
	word-wrap: break-word;
	vertical-align:top;

		box-shadow: 5px 5px transparent,
	    4px 4px transparent, 
	    3px 3px transparent, 
	    2px 2px transparent, 
	    1px 1px transparent,
	    5px 5px 5px gray;
}

.border{
	width: 40%;
	height: 50%;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	margin-top: 14vw;
	border-style: none;
	border-width: .5px;
}

.landingPage{
	background: #b38665;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.greeting{
	color:white;
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
}

.greetingText{
	font-size: 7.4vw;
}

.landingImage{
	position: static;
	background-repeat: no-repeat;
	background-image: url('images/marsPlanet.jpg');
	background-size: contain;

	margin: auto;
	margin-top: 9.68%;
	
	

	color: white;
	background-position: center;
	

	

	height: 60%;
	width: 60%;
}

.continue{
	background-image: url('images/downArrow.png');
	position: static;
  	right: 50%;
  	left: 50%;

	margin-top: 5%;
	margin-left: 47.59%;

  	width: 5em;
  	height: 5em;
  	z-index: 3;
  	background-size: cover;
}

.river{
	background-image: url('images/river.jpg');
}

.doubleDog{
	background-image: url('images/doubleDog.png');	
}

.website{
	background-image: url('images/marsPlanet.jpg');		
}


.highlight{
	text-align: center;
	vertical-align:top;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	margin-top: 1%;
	margin-left:1%; 
	margin-right:1%;
	color: transparent;
	height: 250px;
	width: 11%;
	border: solid;
	border-width: .1px;
	border-color: transparent;
	display: inline-block; 
	padding: 0;

	transition: box-shadow 0.5s;
  	will-change: transform;

	box-shadow: 5px 5px transparent,
	    4px 4px transparent, 
	    3px 3px transparent, 
	    2px 2px transparent, 
	    1px 1px transparent,
	    5px 5px 5px lightgrey;	
}


.spotlight{
	position: relative;
	height: 185px;
	margin-top: 15%;
	color: black;
	margin-left: 15%;
	background: white;
	width: 33%;
}

.spotlightText{
	padding: 3%;
}

.moreInfo{
	font-size: 130%;
	position: relative;
	width: 100%;
	display: inline-block;
	text-align: left;
    margin-bottom: 10%;
    margin-top: 5%;
    height: min-content;	
}

.section{
	width: 70%;
	margin: auto;
	margin-bottom: 5%;
}

.moreInfoBanner{
	background: darkgray;
}

.links{
	margin-top: 3%;
}

.closer{
	float: left;
	margin-left:1%;
	margin-top: 1%; 
	border: none;
}

.underline {
  text-decoration: underline;
}

.remove a{
  text-decoration: none;
}

.hide{
	display: none;
}

.underline{
  text-decoration: underline;
}

.myPic{
	width: 100%;
	box-shadow: 5px 5px transparent,
                4px 4px transparent, 
                3px 3px transparent, 
                2px 2px transparent, 
                1px 1px transparent,
                5px 5px 5px grey;
    
}

.head{
	position: relative;
	font-size: 350%;
	font-size: bold;
}

.intro{
	position: relative;
	font-size: 200%;
	color: indianred;
}

.bio{
	font-size: 120%;
}

.bio.list{
	text-align: left;
}

.hover{
	margin-right: 5%;
}

.ui.pointing.label{
	position: absolute;
	position: fixed !important;
	margin-top: 45px !important;  
	margin-left: -87px !important;
}

.ui.pointing.label.clicked{
	position: absolute;
	margin-left: -69px !important;
	color: purple !important;
}

ui.image {
	position: fixed !important; 
	vertical-align: top !important; 
	 display: inline-block !important; 
}

.footer{
  position: fixed;
  left:0;
  right:0;
  bottom: 0;
  height: 7%;

  font-size: 120%;
  color: lightgray;
  font-weight: bold;
  text-align: center;
  
  display: inline-grid;
  grid-template-columns: 50% 50%;
  
  background: black;
  
  border-color: gray;

  z-index:1 
}


/******************** Project Page **************************/

.projectPage{
	overflow: auto;
	height: 100%;
	position: relative;
    z-index: 0;
		
}

.projectNav{
    margin-top: -4vw !important;	
}

.flex-container{
	
	display: flex;
	width: 100%;
	height: 35%;
	margin-bottom: 5%;
}

.flex-container > div {
	margin: auto;
	max-height: 100%;
	width: 45%;
}

.description{
	height: 100%;
	font-size: 1.5vw;
	width: 70%;
	margin: auto;
}


.projectTitle{
	height: 50%;
	width: auto;
	font-size: 5em;
}

.riverTitle{
	color: #ff2d55; 	
}

.ddTitle{
	color: #01b5ad;
}

.example{

	font-size: 4vw;
}

.projectTitle hr{
	width: 40%;
	margin-top: auto;

}

.projectExample{
	margin-top: 5%;
	height: 9.17em;
	height: 100%;
}

.main{
	background:#fafafa;
	border:solid;
	border-width: 2px;
	border-color: #eeeeee;
	margin: auto;
	width: 100%;
	height: min-content;
	color: black;
	position: relative;
	z-index: -1;
}

.mainDisplay{
	margin-top: 5%;
	position: relative;
	z-index: -1;
}

.sketches{
    margin-top: 3%;
    margin: auto;
    display: flex;
    width: 100vh;
    height: 35%;
    margin-bottom: 5%;
    flex-direction: row;
    flex-wrap: nowrap;
	justify-content: center;
	position: relative;
    z-index: -1;
}

.sketches > img{
	width: 30vw;
	height: 30vw;
	margin: 1%;	
}



/***************** mobile screen homepage *****************/
@media screen and (max-width: 957px){

  #root{
  	overflow: auto;
  }

  .head{
  	margin-top: 5%;
  	width: 250%;
  }

  .myPic{
  	margin-top: 30%;
  }

  .border{
  	width: 40%;
  	margin-top: 22vw;
  }

  .home{
  	display: -webkit-box;
  }

  .featured{
	height: 30%;
	width: 100%;
	text-align: center;
	align-self: center;
  }

  .projects{
  	display: flex;
	justify-content: center;
  }

  .highlight{
  	width: 19%;
  	margin-top: 1%;
  }

  .onlight{
	width: 19%;	
	margin-top: 1%;
  }

  .AFlex{
	margin-top: 10%;
	overflow: none;
  }

  .navbar{
	position: fixed;
	margin-left: 91.4%;
	margin-top: 11%;

	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;	

	z-index: 4;
	width: 8.5vw;
	background: transparent;
  }

  .navbar img{
  	background: white !important;
  	z-index: 5;
  }

  .menu{
  	top: 0;
  	 margin-top: 10em;
  	 width: 100%;
  	 position: fixed;
  	 display: inline-block;
  	 z-index: 5;
  }


  .inMenu{
  	width: 100%;
  	color: white;
  	background: black;
  	border-style: solid;
  	border-width: .1px;
  	width: 100%;
  	padding: 1%;
  }

  .landingImage{
	height: 80%;
	width: 80%;
  }

  .riverPage{
	margin-top: 1em;
	height: 100%;
	z-index: -1;	
}

.projectNav{
	position: static !important;
    margin-top: -8vw !important;	
}

.flex-container{
	margin-top: 3%;
}

.description{
	font-size: 1.9vw;
	
}

.sketches{
	width: 100%;
	flex-wrap: wrap;
	margin: auto;
}

.sketches >img {
	margin: auto;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 100%;
	height: auto;
}

.projectTitle{
	font-size: 3em;
}

.inFlex{
	margin: 6vw;
}


  .continue{
	margin-top: -5%;
	margin-left: 46%;
  }
}



@media screen and (max-width: 700px){
  .inMenu{
  	padding: 6%;
  }  

  .border{
  	width: 60%;
    margin-top: 25vw;
  }

  .featured{
	height: 30%;
	width: 100%;
  }

  .greetingText{
	font-size: 17vw;
  }

  .projects{
  	margin-top: 6%;
  	margin-left: .2%;
  	position: fixed;
	height: 30%;
	width: 100%;
	justify-content: center;
  }

  .AFlex{
	margin-top: 15%;
	overflow: auto;
  }

  .inFlex.myPic{
	width: 45%;
	margin-top: -25%;
	height: 100%;
  }

  .inFlex{
	margin: 5%;
	margin-top: 10%;
	margin-bottom: 10%;
	align-items: center;
	flex: 50%;
	height: 100%;
	width: 100%;
	
}

  .highlight{
  	width: 22%;
  }

  .onlight{
	width: 22%;	
  }

  .head{
	position: relative;
	z-index: 1;
	font-size: 350%;
	font-size: bold;
  }

  .navbar{
	margin-top: 9%;
	margin-left: 90vw;
  } 


  .background2{
  	margin-left: -1.6%;
  	width: 105%;
  }

  .background2-2{
  	margin-left: -1.6%;
  	width: 105%;
  }

.continue{
	margin-top: -5%;
	margin-left: 43%;
}

}
